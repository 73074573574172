import { ExtendedSubscriptionTemplate, NewUser, ExtendedTeam, TeamSubscriptionPrice } from "@/prisma/types";
import { PlatformSubscriptionPriceInstance, SubscriptionPriceInstance, SubscriptionTemplate } from "@prisma/client";
import moment from "moment";

export const getMostExpensiveSubscription = (subscriptionTemplates: ExtendedSubscriptionTemplate[]) => {
    // this function returns the subscription plan with the most expensive base price
    // Ensure the array is not empty before reducing
    if (!subscriptionTemplates || subscriptionTemplates.length === 0) {
        return null; // or undefined, or throw an error, depending on your use case
    }

    // Use reduce to find the subscription with the highest base price
    return subscriptionTemplates.reduce((prev, current) => {
        // Default to 0 if basePrice is undefined or null
        const prevBasePrice = prev?.defaultSubscriptionPrices?.[0]?.basePrice ?? 0;
        const currentBasePrice = current?.defaultSubscriptionPrices?.[0]?.basePrice ?? 0;

        // Return the subscription with the higher base price
        return currentBasePrice > prevBasePrice ? current : prev;
    });
}

export const getMostExpensiveTeamSubscription = (team: ExtendedTeam) => {

    if (team.subscriptionInstances === null || team.subscriptionInstances === undefined || team.subscriptionInstances.length === 0) {
        console.log("team has no subscriptions, returning null")
        // if not found, no active subscription, return null
        return null
    }

    const teamActiveSubscriptions = team.subscriptionInstances.filter((instance) => {
        return (instance.status === "ACTIVE" && instance.endDate !== null && instance.endDate >= new Date())
    })

    const activeTemplates = teamActiveSubscriptions.map((instance) => instance.subscriptionTemplate)

    if (activeTemplates.length === 0) {
        console.log("team has no active subscription templates. returning null")
        return null
    }

    return getMostExpensiveSubscription(activeTemplates as ExtendedSubscriptionTemplate[])
}

export const calculatePackagePrice = ({ subscriptionTemplate, subscriptionDuration, isFirstUser }: {
    subscriptionTemplate: ExtendedSubscriptionTemplate,
    subscriptionDuration: number,
    isFirstUser: boolean
}) => {

    if (subscriptionTemplate.defaultSubscriptionPrices === null || subscriptionTemplate.defaultSubscriptionPrices === undefined) {
        console.error("Error calculating user price, default subscription prices not found")
        return null
    }

    const basePrice = subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === subscriptionDuration)?.basePrice
    const userPrice = subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === subscriptionDuration)?.userPrice

    if (basePrice === null || basePrice === undefined || userPrice === null || userPrice === undefined) {
        console.error("Error calculating user price, base price or user price not found")
        return null
    }

    return isFirstUser ? basePrice + userPrice : userPrice
}

export const calculateRemainingPackagePrice = ({ subscriptionStartDate, subscriptionEndDate, currentDate, subscriptionDuration, subscriptionTemplate, isFirstUser }: {
    subscriptionStartDate: Date,
    subscriptionEndDate: Date,
    currentDate: Date | null,
    subscriptionDuration: number | null,
    subscriptionTemplate: ExtendedSubscriptionTemplate,
    isFirstUser: boolean
}) => {

    const months = calculateMonths(subscriptionStartDate, subscriptionEndDate)
    const days = calculateDays(subscriptionStartDate, subscriptionEndDate)

    // if no current date is provided, use the start date of the subscription and get the full price.
    const remainingDays = currentDate !== null ? calculateDays(currentDate, subscriptionEndDate) : days

    const monthPrice = calculatePackagePrice({
        subscriptionTemplate: subscriptionTemplate,
        subscriptionDuration: subscriptionDuration !== null ? subscriptionDuration : months,
        isFirstUser: isFirstUser
    }) ?? 0

    const fullPrice = monthPrice * calculateMonths(subscriptionStartDate, subscriptionEndDate, true)

    // console.log("months: ", months)
    // console.log("days: ", days)
    // console.log("remaining days: ", remainingDays)
    // console.log("remaining months: ", calculateMonths(subscriptionStartDate, subscriptionEndDate, true))

    // console.log("price fraction: ", remainingDays / days)
    // console.log("price fraction * price: ", (remainingDays / days) * fullPrice)

    return Math.ceil((remainingDays / days) * fullPrice)
}

const calculateMonths = (subscriptionStartDate: Date, subscriptionEndDate: Date, fractional: boolean = false) => {
    const date1 = moment(subscriptionStartDate);
    const date2 = moment(subscriptionEndDate);

    // Calculate the difference in months
    const monthDifference = date2.diff(date1, 'months', fractional);
    return monthDifference;
}

const calculateDays = (subscriptionStartDate: Date, subscriptionEndDate: Date) => {
    const date1 = moment(subscriptionStartDate);
    const date2 = moment(subscriptionEndDate);

    // Calculate the difference in days
    const dayDifference = date2.diff(date1, 'days');
    return dayDifference;
}


export const getUserPriceTexts = ({users, subscriptionTemplates, subscriptionDuration} : {users: NewUser[], subscriptionTemplates: ExtendedSubscriptionTemplate[], subscriptionDuration: number} ) => {
    let texts: {
        text: string,
        price: number
    }[] = []

    const mostExpensivePlanSelected = subscriptionTemplates.find((template) => template.id === Math.max(...users.map((user) => user.subscriptionTemplate.id)))

    if (mostExpensivePlanSelected === null || mostExpensivePlanSelected === undefined) {
        return texts;
    }

    const firstUserPackagePrice = mostExpensivePlanSelected.defaultSubscriptionPrices?.find((price) => price.duration === subscriptionDuration)

    const firstUserPrice = (firstUserPackagePrice?.basePrice ?? 0) + (firstUserPackagePrice?.userPrice ?? 0)

    const firstUserTextLine = {
        text: "Main CM Navigator " + mostExpensivePlanSelected.name + " subscription",
        price: firstUserPrice
    }

    texts.push(firstUserTextLine)

    let selectedPlans = users.map((user) => user.subscriptionTemplate).filter((template) => template.id !== mostExpensivePlanSelected.id)

    // const indexToRemove = selectedPlanIds.indexOf(mostExpensivePlanSelected.id);
    // if (indexToRemove > -1) {
    //     selectedPlanIds.splice(indexToRemove, 1);
    // }

    selectedPlans.forEach((selectedPlan) => {
        const price = selectedPlan?.defaultSubscriptionPrices?.find((price) => price.duration === subscriptionDuration)

        const userPrice = price?.userPrice ?? 0

        const textLine = {
            text: "+1 CM Navigator " + selectedPlan?.name + " subscription",
            price: userPrice
        }
        texts.push(textLine)
    })
    return texts;
}

export const getBaseSubscription = ( { teamSubscriptionPrices }: {
    teamSubscriptionPrices: TeamSubscriptionPrice[]
}) => {
    // the base subscription is the most expensive plan that has an active subscription
    const activeSubscriptionPrices = teamSubscriptionPrices.filter((price) => price.hasActiveSubscription) ?? []

    if (activeSubscriptionPrices.length === 0) {
        return null
    }

    const mostExpensiveActivePlan = activeSubscriptionPrices.reduce((prev, current) => {
        return prev.basePrice > current.basePrice ? prev : current
    })

    console.log("most expensive active plan: ", mostExpensiveActivePlan)
    return mostExpensiveActivePlan
}

export const getDefaultBasePrice = ( { newUser, subscriptionDuration }: {
    newUser: NewUser,
    subscriptionDuration: number
}) => {
    return newUser.subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === subscriptionDuration)?.basePrice
}

export const getBasePrice = ( { platformSubscriptionPrices, templateId} : {
    platformSubscriptionPrices: PlatformSubscriptionPriceInstance[],
    templateId: number,
}) => {
    return platformSubscriptionPrices.find((platformSubscriptionPrice) => platformSubscriptionPrice.subscriptionTemplateId === templateId)?.price
}

export const getUserPrice = ( { subscriptionPrices, templateId } : {
    subscriptionPrices: SubscriptionPriceInstance[],
    templateId: number
}) => {
    return subscriptionPrices.find((subscriptionPrice) => subscriptionPrice.subscriptionTemplateId === templateId)?.price
}

export const isFirstUser = ({mostExpensiveSubscription, newSubscription, subscriptionDuration}:{
    mostExpensiveSubscription: ExtendedSubscriptionTemplate | null, 
    newSubscription: ExtendedSubscriptionTemplate,
    subscriptionDuration: number
}) => {
    // if the most expensive subscription is null, the new user is the first user
    if (mostExpensiveSubscription === null || mostExpensiveSubscription === undefined) {
        return true
    }

    console.log("most expensive subscription: ", mostExpensiveSubscription)
    console.log("new subscription: ", newSubscription)
    
    const mostExpensivePrice = mostExpensiveSubscription.defaultSubscriptionPrices?.find((price) => price.duration === subscriptionDuration)?.basePrice
    const newPrice = newSubscription.defaultSubscriptionPrices?.find((price) => price.duration === subscriptionDuration)?.basePrice

    return (newPrice ?? 0) > (mostExpensivePrice ?? 0)
}

export const calculatePriceForNewSubscription = ({
    newUsers, subscriptionTemplates, team
}: {
    newUsers: NewUser[],
    subscriptionTemplates: ExtendedSubscriptionTemplate[],
    team: ExtendedTeam
}) => {
    console.log("team subscription prices: ", team.subscriptionPrices)
    console.log("team platform subscription prices: ", team.platformSubscriptionPrices)

    console.log("new users: ", newUsers)

    const subscriptionPrices = team.subscriptionPrices
    const platformPrices = team.platformSubscriptionPrices

    if (newUsers.length === 0 || team.subscriptionDuration === null || team.subscriptionDuration === undefined ) {
        console.error("Error calculating user prices, no new users or subscription duration not found")
        return null
    }

    if (subscriptionPrices === null || subscriptionPrices === undefined) {
        console.error("Error calculating user prices, team subscription prices not found")
        return null
    }

    if (platformPrices === null || platformPrices === undefined) {
        console.error("Error calculating user prices, team platform subscription prices not found")
        return null
    }

    const newUsersSorted = sortNewUsersBySubscriptionPrice(newUsers, team.subscriptionDuration)

    let mostExpensiveNewUser = newUsersSorted[0]

    let mostExpensiveBasePrice = getBasePrice({ platformSubscriptionPrices: platformPrices, templateId: mostExpensiveNewUser.subscriptionTemplate.id }) ?? 0

    let mostExpensiveUserPrice = getUserPrice({ subscriptionPrices: subscriptionPrices, templateId: mostExpensiveNewUser.subscriptionTemplate.id }) ?? 0
    
    let newUserPrices: ({
        subscriptionTemplate: ExtendedSubscriptionTemplate,
        userPrice: number,
        basePrice: number
    } | null)[] = [{
        subscriptionTemplate: mostExpensiveNewUser.subscriptionTemplate,
        userPrice: mostExpensiveUserPrice,
        basePrice: mostExpensiveBasePrice
    }]

    let otherNewUsers = newUsersSorted.slice(1)
    
    console.log("other new users: ", otherNewUsers)
    // prices for users, who are not the most expensive, e.g. non-first-users   
    const otherNewUserPrices: ({
        subscriptionTemplate: ExtendedSubscriptionTemplate,
        userPrice: number,
        basePrice: number
    } | null)[] = otherNewUsers.map((user) => {
        const userPrice = getUserPrice({ subscriptionPrices: subscriptionPrices, templateId: user.subscriptionTemplate.id })

        console.log(":platformPrices :", platformPrices)
        const basePrice = getBasePrice({ platformSubscriptionPrices: platformPrices, templateId: user.subscriptionTemplate.id })

        if (userPrice === null || userPrice === undefined) {
            console.error("Error calculating user prices, user price not found")
            return null
        }

        console.log("base price: ", basePrice)
        console.log("most expensive base price: ", mostExpensiveBasePrice)


        return {
            subscriptionTemplate: user.subscriptionTemplate,
            userPrice: userPrice,
            basePrice: 0
        }
        

    }) ?? []

    newUserPrices = newUserPrices.concat(otherNewUserPrices ?? [])

    console.log("newUserPrices: ", newUserPrices)

    const totalPricesForPeriod = newUserPrices.map((price) => {
        if (price === null) {
            return 0
            
        }
        return (price.basePrice + price.userPrice) * team.subscriptionDuration
    })
    

    console.log("newUserPrices: ", newUserPrices)
    console.log("totalPriceForPeriod: ", totalPricesForPeriod.reduce((a, b) => a + b, 0))

    const totalPriceForPeriod = totalPricesForPeriod.reduce((a, b) => a + b, 0)
    
    return totalPriceForPeriod
}

// users: NewUser[], subscriptionTemplates: ExtendedSubscriptionTemplates[], subscriptionDuration: number
export const calculateUserPrices = ({ newUsers, subscriptionTemplates, team, teamSubscriptionPrices }: {
    newUsers: NewUser[],
    subscriptionTemplates: ExtendedSubscriptionTemplate[],
    team: ExtendedTeam,
    teamSubscriptionPrices: TeamSubscriptionPrice[]
}) => {
    console.log("new users: ", newUsers)

    if (newUsers.length === 0) {
        return 0
    }

    const newUsersSorted = sortNewUsersBySubscriptionPrice(newUsers, team.subscriptionDuration)

    // get all plans where at least one team member has an active subscription
    const activeSubscriptionPrices = teamSubscriptionPrices.filter((price) => price.hasActiveSubscription) ?? []

    if (activeSubscriptionPrices.length === 0) {
        return calculatePriceForNewSubscription({
            newUsers: newUsers,
            subscriptionTemplates: subscriptionTemplates,
            team: team
        })
    }
    const mostExpensiveActivePlan = activeSubscriptionPrices.reduce((prev, current) => {
        return prev.basePrice > current.basePrice ? prev : current
    })

    // const mostExpensiveActivePlanTemplate = subscriptionTemplates.find((template) => template.id === mostExpensiveActivePlan.subscriptionTemplateId)


    // to calculate the total price, we need the following:
    // 1. the base price of the most expensive plan
    // 2. the user price of the most expensive plan
    // 3. the user price of the other plans
    // 4. the number of users for each plan
    // 5. the subscription duration
    // 6. the start date of the subscription
    // 7. the end date of the subscription
    // 8. the remaining days of the current month
    // 9. the remaining days of the current year
    // 10. the total price of the subscription as a fraction of the remaining days of the current subscription period

    // const activeSubscriptions = team.subscriptionInstances?.filter((instance) => instance.status === "ACTIVE")

    // let currentBaseSubscription: ExtendedSubscriptionTemplates | null = getMostExpensiveTeamSubscription(team)

    // if (activeSubscriptions && activeSubscriptions.length > 0) {
    //     // TODO: handle case where there are already a new base subscription

    //     console.log("currenctly the team has active subscriptions: ", activeSubscriptions)
    //     baseSubscription = getMostExpensiveTeamSubscription(team)

    //     if (baseSubscription === null || baseSubscription === undefined) {
    //         console.error("Error calculating user prices, base subscription price not found")
    //         return null
    //     }
    // }

    // if (currentBaseSubscription === null || currentBaseSubscription === undefined) {
    //     console.error("team has no active subscriptions, treat as first user / new subscription")
    // }

    // console.log("base subscription: ", currentBaseSubscription)

    // let currentBasePrice = currentBaseSubscription?.defaultSubscriptionPrices?.find((price) => price.duration === team.subscriptionDuration)?.basePrice

    let mostExpensiveNewUser = newUsersSorted[0]
    console.log("most expensive new user: ", mostExpensiveNewUser)

    let mostExpensiveBasePrice = mostExpensiveActivePlan.basePrice
    
    const newUserPrices: ({
        subscriptionTemplate: ExtendedSubscriptionTemplate,
        userPrice: number,
        basePrice: number
    } | null)[] = newUsersSorted.map((user) => {
        const userPrice = teamSubscriptionPrices.find((price) => price.subscriptionTemplateId === user.subscriptionTemplate.id)

        if (userPrice === null || userPrice === undefined || userPrice.basePrice === null || userPrice.basePrice === undefined || userPrice.userPrice === null || userPrice.userPrice === undefined) {
            console.error("Error calculating user prices, user price not found")
            return null
        }

        if (userPrice.basePrice - mostExpensiveBasePrice > 0) {
            mostExpensiveBasePrice = userPrice.basePrice
            return {
                subscriptionTemplate: user.subscriptionTemplate,
                userPrice: userPrice.userPrice ?? 0,
                basePrice: mostExpensiveBasePrice ? userPrice.basePrice : 0
            }
        } else {
            return {
                subscriptionTemplate: user.subscriptionTemplate,
                userPrice: userPrice.userPrice,
                basePrice: 0
            }
        }

    }) ?? []

    const totalPricesForPeriod = newUserPrices.map((price) => {
        if (price === null) {
            return 0
            
        }
        return (price.basePrice + price.userPrice) * team.subscriptionDuration
    })
    

    console.log("newUserPrices: ", newUserPrices)
    console.log("totalPriceForPeriod: ", totalPricesForPeriod.reduce((a, b) => a + b, 0))

    const totalPriceForPeriod = totalPricesForPeriod.reduce((a, b) => a + b, 0)

    const oldestActiveInstance = team.subscriptionInstances?.reduce((prev, current) => {
        if (prev === null || prev === undefined || prev.startDate === null || prev.startDate === undefined || current.startDate === null || current.startDate === undefined) {   
            return current
        }
        return prev.startDate < current.startDate ? prev : current
    })

    if (oldestActiveInstance === null || oldestActiveInstance === undefined) {
        console.error("Error calculating user prices, oldest active instance not found")
        return null
    }

    const startDate = oldestActiveInstance.startDate ?? new Date()
    const endDate = oldestActiveInstance.endDate ?? new Date()



    const days = calculateDays(startDate, endDate)
    const remainingDays = calculateDays(new Date(), endDate)


    console.log("days: ", days)
    console.log("remaining days: ", remainingDays)

    console.log("price fraction: ", remainingDays / days)
    console.log("price fraction * price: ", (remainingDays / days) * totalPriceForPeriod)
    const fractionalPrice = (remainingDays / days) * totalPriceForPeriod
    return Math.ceil( fractionalPrice ); 

    // if (mostExpensiveNewUser === null || mostExpensiveNewUser === undefined) {
    //     console.error("Error calculating user prices, most expensive new user plan not found")
    //     return null
    // }

    // const mostExpensiveNewUserBasePrice = mostExpensiveNewUser.subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === team.subscriptionDuration)?.basePrice

    // console.log("most expensive new user: ", mostExpensiveNewUser)

    // console.log("current base price: ", currentBasePrice)
    // console.log("most expensive new user base price: ", mostExpensiveNewUserBasePrice)
    // if ((currentBasePrice ?? 0) > (mostExpensiveNewUserBasePrice ?? 0)) {
    //     console.log("current base price is more expensive than the most expensive new user, don't include difference in base price for remaining period")
    // } else {
    //     console.log("current base price is less expensive than the most expensive new user, include difference in base price for remaining period")
    // }

    // const mostExpensiveNewUserPrice = mostExpensiveNewUser.subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === team.subscriptionDuration)?.basePrice ?? 0

    // if (currentBasePrice && currentBasePrice < mostExpensiveNewUserPrice) {
    //     // a user with a more expensive plan has been added
    //     currentBasePrice = mostExpensiveNewUser.subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === team.subscriptionDuration)?.basePrice ?? 0

    //     baseSubscription = mostExpensiveNewUser.subscriptionTemplate
    // }

    // console.log("new base price: ", currentBasePrice)
}

export const sortNewUsersBySubscriptionPrice = (newUsers: NewUser[], duration: number) => {
    const newUsersSorted = newUsers;
    newUsersSorted.sort((a, b) => {
        const aPrice = a?.subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === duration)?.basePrice ?? 0;
    
        const bPrice = b?.subscriptionTemplate.defaultSubscriptionPrices?.find((price) => price.duration === duration)?.basePrice ?? 0;
        return bPrice - aPrice 
    }) ?? []
    // console.log("new users sorted: ", newUsersSorted)

    return newUsersSorted
}



export const formatUserPriceTexts = ({ activeBaseSubscription, newBaseSubscription, selectedPlans }: {
    activeBaseSubscription: {
        planName: string;
        price: number;
    } | null,
    newBaseSubscription: {
        planName: string;
        price: number;
    } | null,
    selectedPlans: {
        planName: string;
        planPrice: number;
    }[]
}) => {
    let texts: {
        text: string,
        price: number
    }[] = []

    // there is already an existing base subscription
    if (activeBaseSubscription) {

        // is there a new base subscription that is more expensive than the current one?
        if (newBaseSubscription && activeBaseSubscription.price < newBaseSubscription.price) {
            texts.push({
                text: "Main CM Navigator " + newBaseSubscription.planName + " subscription",
                price: newBaseSubscription.price
            })
        } else {
            texts.push({
                text: "Main CM Navigator " + activeBaseSubscription.planName + " subscription",
                price: activeBaseSubscription.price
            })
        }
    }

    return texts;
};


// SIMPLIFIED VERSION
export const calculateMonthlyPrice = ({ teamPrices, newUsers }:  {
    teamPrices: TeamSubscriptionPrice[]
    newUsers: NewUser[]
}) => {
    // most expensive active price
    const teamActivePrices = teamPrices
        .filter((price) => price.hasActiveSubscription)
        .sort((a, b) => b.basePrice - a.basePrice)

    let mostExpensiveActivePrice = teamActivePrices.length > 0 ? teamActivePrices[0].basePrice : 0
    let mostExpensiveTemplateId = teamActivePrices.length > 0 ? teamActivePrices[0].subscriptionTemplateId : null
    let didCountBasePrice = true

    const newUsersSortedByPrice = newUsers.sort((a, b) => {
        const aPrice = teamPrices.find((price) => price.subscriptionTemplateId === a.subscriptionTemplate.id)?.basePrice ?? 0
        const bPrice = teamPrices.find((price) => price.subscriptionTemplateId === b.subscriptionTemplate.id)?.basePrice ?? 0
        return bPrice - aPrice
    })


    if (newUsersSortedByPrice.length > 0) {
        const mostExpensiveNewUserTeamPrice = teamPrices.find((price) => price.subscriptionTemplateId === newUsersSortedByPrice[0].subscriptionTemplate.id)?.basePrice ?? 0

        if (mostExpensiveActivePrice < mostExpensiveNewUserTeamPrice) {
            console.log("should hit this")
            mostExpensiveTemplateId = newUsersSortedByPrice[0].subscriptionTemplate.id
            mostExpensiveActivePrice = mostExpensiveNewUserTeamPrice
            didCountBasePrice = false
        }
    }

    console.log("most expensive active price: ", mostExpensiveActivePrice)

    let total = 0
    newUsers.forEach((newUser) => {
        const userPrice = teamPrices.find((price) => price.subscriptionTemplateId === newUser.subscriptionTemplate.id)

        if (userPrice === null || userPrice === undefined) {
            console.error("Error calculating user prices, user price not found")
            return null
        }

        let basePrice = 0
        if (didCountBasePrice === false && mostExpensiveTemplateId === newUser.subscriptionTemplate.id) {
            didCountBasePrice = true
            basePrice = userPrice.basePrice
        }

        const price = basePrice + userPrice.userPrice
        total += price
    })

    return total

}